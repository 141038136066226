import React from 'react';
import {
  CogIcon,
  HomeIcon,
  MoneyIcon,
  OrderedListIcon,
  TruckIcon,
  VerticalStacksIcon,
} from '@nimbus-ds/icons';
import { NavTabs } from '@nimbus-ds/patterns';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tooltip } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';

const NavButtons: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleGoToConfigs = (page: string) => navigate(page);

  const { pathname } = useLocation();

  return (
    <NavTabs>
      <NavTabs.Item
        active={pathname === '/'}
        ariaLabel={`${t('Components.NavButtons.Home.Label')}`}
        icon={
          <Tooltip
            content={`${t('components.NavButtons.Home.Helper')}`}
            position="top"
          >
            <HomeIcon size="medium" />
          </Tooltip>
        }
        onClick={() => {
          handleGoToConfigs('/');
        }}
      />
      <NavTabs.Item
        active={pathname === '/orders'}
        ariaLabel={`${t('components.NavButtons.Orders.Label')}`}
        icon={
          <Tooltip
            content={`${t('components.NavButtons.Orders.Helper')}`}
            position="top"
          >
            <OrderedListIcon size="medium" />
          </Tooltip>
        }
        onClick={() => {
          handleGoToConfigs('/orders');
        }}
      />
      <NavTabs.Item
        active={pathname.startsWith('/shipping-config')}
        ariaLabel={`${t('components.NavButtons.ShippingConfig.Label')}`}
        icon={
          <Tooltip
            content={`${t('components.NavButtons.ShippingConfig.Helper')}`}
            position="top"
          >
            <TruckIcon size="medium" />
          </Tooltip>
        }
        onClick={() => {
          handleGoToConfigs('/shipping-config');
        }}
      />
      <NavTabs.Item
        active={pathname.startsWith('/distribution-centers')}
        ariaLabel={`Centros de Distribuição`}
        icon={
          <Tooltip content={`Centros de Distribuição`} position="top">
            <VerticalStacksIcon size="medium" />
          </Tooltip>
        }
        onClick={() => {
          handleGoToConfigs('/distribution-centers');
        }}
      />
      <NavTabs.Item
        active={pathname === '/store-config'}
        ariaLabel={`${t('components.NavButtons.StoreConfig.Label')}`}
        icon={
          <Tooltip
            content={`${t('components.NavButtons.StoreConfig.Helper')}`}
            position="top"
          >
            <CogIcon size="medium" />
          </Tooltip>
        }
        onClick={() => {
          handleGoToConfigs('/store-config');
        }}
      />
      <NavTabs.Item
        active={pathname === '/billing'}
        ariaLabel={`${t('components.NavButtons.Billing.Label')}`}
        icon={
          <Tooltip
            content={`${t('components.NavButtons.Billing.Helper')}`}
            position="top"
          >
            <MoneyIcon size="medium" />
          </Tooltip>
        }
        onClick={() => {
          handleGoToConfigs('/billing');
        }}
      />
    </NavTabs>
  );
};

export default NavButtons;
