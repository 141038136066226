import axios from 'axios';
import { getSessionToken, getStoreInfo } from '@tiendanube/nexo';
import nexo from '../nexoClient';

const apiInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URI}`,
});

apiInstance.interceptors.request.use(async (request) => {
  const token = await getSessionToken(nexo);
  const store = await getStoreInfo(nexo);
  const bearerToken = `Bearer ${token}`;
  request.headers = {
    ...request.headers,
    Authorization: bearerToken,
    'Store-Country': `${store.country}`,
  } as any;
  return request;
});

apiInstance.interceptors.response.use(
  (response) => response, // Deixar respostas bem-sucedidas inalteradas
  (error) => {
    // Logar o erro para depuração
    console.error('Erro na API:', error);

    // Adicionar lógica adicional para erros, se necessário
    if (error.response) {
      console.error('Detalhes do erro:', error.response.data);
    }

    // Rejeitar a promessa com o erro para que possa ser tratado no `catch`
    return Promise.reject(error);
  },
);

export default apiInstance;
