import { INSShippingCarrier } from './shipping-carrier.interface';

export interface ISpecialPrice {
  date: Date;
  price: number;
}

export interface IShippingConfigPeriod {
  _id?: string;
  store_id: number;
  external_id: number;
  code: string;
  active: boolean;
  name: string;
  time: string; // Ex.: "08:00-12:00"
  observation?: string;
  cut_off_week: { time: string; previous_day: boolean }; // Corte semana
  cut_off_saturday: { time: string; previous_day: boolean }; // Corte sábado
  cut_off_saturday_interior: { time: string; previous_day: boolean }; // Corte sábado interior
  cut_off_sunday: { time: string; previous_day: boolean }; // Corte domingo
  position: number;
  period_prices?: Array<{
    week_price: number;
    saturdays_price: number;
    sundays_price: number;
  }>;
}

export interface IShippingConfigPeriodPrice {
  _id?: string;
  store_id: number;
  period_id: string; // Referência ao período
  zip_band_id: string; // Referência à faixa de CEP
  week_price: number;
  saturdays_price: number;
  sundays_price: number;
  special_prices: ISpecialPrice[];
}

export interface IShippingConfigZipBand {
  _id?: string;
  store_id: number;
  active: boolean;
  zip_start: string; // "12345-000"
  zip_end: string; // "12349-999"
  city: string;
  state: string; // Ex.: "SP"
  is_interior: boolean; // Indica se é uma faixa de CEP do interior
  periods: string[]; // Referências aos períodos
}

export interface IShippingConfigCarrier {
  _id?: string;
  name: string;
  store_id: number;
  week_external_id: number;
  saturday_external_id: number;
  sunday_external_id: number;
  week_ns_object?: INSShippingCarrier;
  saturday_ns_object?: INSShippingCarrier;
  sunday_ns_object?: INSShippingCarrier;
}

export const EmptyShippingConfigCarrier: IShippingConfigCarrier = {
  name: '',
  store_id: -1,
  week_external_id: -1,
  saturday_external_id: -1,
  sunday_external_id: -1,
};

export const EmptyShippingConfigZipBand: IShippingConfigZipBand = {
  store_id: -1,
  active: true,
  is_interior: false,
  zip_start: '',
  zip_end: '',
  city: '',
  state: '',
  periods: [],
};

export const EmptyShippingConfigPeriods: IShippingConfigPeriod = {
  store_id: -1,
  external_id: -1,
  code: '',
  active: true,
  name: '',
  time: '',
  observation: '',
  cut_off_week: { time: '', previous_day: false },
  cut_off_saturday: { time: '', previous_day: false },
  cut_off_saturday_interior: { time: '', previous_day: false },
  cut_off_sunday: { time: '', previous_day: false },
  position: 1,
};
