export interface IDistributionCenterZipBand {
  label: string;
  zipcode_start: string;
  zipcode_end: string;
}

export interface IDistributionCenter {
  _id?: string;
  store_id: number;
  base: string;
  zip_bands: IDistributionCenterZipBand[];
}

export const EmptyDistributionCenter: IDistributionCenter = {
  store_id: -1,
  base: '',
  zip_bands: [],
};

export const EmptyDistributionCenterZipBand: IDistributionCenterZipBand = {
  label: '',
  zipcode_start: '',
  zipcode_end: '',
};
