import React, { ChangeEvent, useEffect, useState } from 'react';
import nexo from '../../nexoClient';
import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import { navigateHeader } from '@tiendanube/nexo';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Input,
  Select,
  Spinner,
  Title,
  Toggle,
  useToast,
} from '@nimbus-ds/components';
import {
  defaultStoreConfig,
  IStoreConfig,
} from '../../lib/interfaces/store-config.interface';
import apiInstance from '../../utils/apiUtils';

const ConfigsPage: React.FC = () => {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [loading, setLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<IStoreConfig>({
    ...defaultStoreConfig,
  });

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `${t('general.back')}` });
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const result = await apiInstance.get(`/store-config`);
      if (result.data !== null) {
        const resData = result.data;
        setFormData({ ...defaultStoreConfig, ...resData });
      }
    } catch (error) {
      setFormData({ ...defaultStoreConfig });
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'danger',
        text: `${t('ConfigsPage.Toasts.Error.GetConfig')}`,
        position: 4,
      });
    }
    setLoading(false);
  };

  const handleChangeForm = (
    event: ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;

    setFormData((prevState) => {
      // Verifica se o campo é aninhado
      const keys = name.split('.');
      const updatedState: any = { ...prevState }; // Alterado para const

      if (keys.length > 1) {
        let nestedState = updatedState;
        for (let i = 0; i < keys.length - 1; i++) {
          if (!nestedState[keys[i]]) nestedState[keys[i]] = {};
          nestedState = nestedState[keys[i]];
        }
        nestedState[keys[keys.length - 1]] =
          type === 'checkbox' && 'checked' in event.target
            ? event.target.checked
            : value;
      } else {
        updatedState[name] =
          type === 'checkbox' && 'checked' in event.target
            ? event.target.checked
            : value;
      }

      return updatedState;
    });
  };

  const handleSubmit = async () => {
    if (!formData) return;

    try {
      setLoading(true);
      const payload = { ...formData };

      // Remova os campos que não precisam ser enviados
      delete payload._id;
      delete payload.createdAt;
      delete payload.updatedAt;

      const result = await apiInstance.post(`/store-config`, payload);

      if (result.status === 200 && result.data.status) {
        addToast({
          id: `submit_${new Date().toISOString()}`,
          type: 'success',
          text: `${t('ConfigsPage.Toasts.Success.Save')}`,
          position: 4,
        });
      } else {
        throw new Error('Erro ao salvar configurações');
      }
    } catch (error) {
      addToast({
        id: `submit_${new Date().toISOString()}`,
        type: 'danger',
        text: `${t('ConfigsPage.Toasts.Error.Save')}`,
        position: 4,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page>
      <Page.Header title={t('ConfigsPage.Title')} />
      <Page.Body>
        <Layout columns="1">
          <Layout.Section>
            <Box display="grid" gap="6" boxSizing="border-box">
              {loading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  <Spinner size="large" />
                </Box>
              ) : (
                <>
                  {/* Formulário de Agendamento de Entrega */}
                  <Card>
                    <Card.Header>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Title as="h4">{`${t(
                          'ConfigsPage.Cards.DeliveryScheduling.Title',
                        )}`}</Title>
                        <Toggle
                          name="deliveryScheduling.active"
                          label={`${t(
                            'ConfigsPage.Cards.DeliveryScheduling.Inputs.Active.Label',
                          )}`}
                          disabled={loading}
                          active={formData.deliveryScheduling?.active}
                          onChange={handleChangeForm}
                        />
                      </Box>
                    </Card.Header>
                    <Card.Body>
                      <Box display="grid" gap="6">
                        <FormField
                          label={`${t(
                            'ConfigsPage.Cards.DeliveryScheduling.Inputs.calendaryTitle.Label',
                          )}`}
                        >
                          <Input
                            disabled={loading}
                            id="deliveryScheduling.calendaryTitle"
                            name="deliveryScheduling.calendaryTitle"
                            onChange={handleChangeForm}
                            value={
                              formData.deliveryScheduling?.calendaryTitle || ''
                            }
                          />
                        </FormField>
                      </Box>
                    </Card.Body>
                  </Card>

                  {/* Formulário de Configurações de Impressora */}
                  <Card>
                    <Card.Header>
                      <Title as="h4">Configurações de Impressora</Title>
                    </Card.Header>
                    <Card.Body>
                      <Box display="grid" gap="6">
                        <FormField label="Largura do Papel">
                          <Select
                            id="printerConfig.paperWidth"
                            name="printerConfig.paperWidth"
                            value={formData.printerConfig.paperWidth}
                            onChange={handleChangeForm}
                          >
                            <Select.Option value="80mm" label="80mm" />
                            <Select.Option value="58mm" label="58mm" />
                          </Select>
                        </FormField>
                        <FormField label="Tamanho da Fonte">
                          <Input
                            type="number"
                            name="printerConfig.fontSize"
                            value={formData.printerConfig.fontSize}
                            onChange={handleChangeForm}
                            placeholder="Ex.: 12"
                          />
                        </FormField>
                        <FormField label="Habilitar QRCode de Entrega">
                          <Toggle
                            name="printerConfig.enableQRCode"
                            label="Ativar"
                            active={formData.printerConfig.enableQRCode}
                            onChange={handleChangeForm}
                          />
                        </FormField>
                      </Box>
                    </Card.Body>
                  </Card>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      disabled={loading}
                      appearance="primary"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      {loading ? (
                        <Spinner color="currentColor" size="small" />
                      ) : (
                        ''
                      )}
                      {t('general.save')}
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Layout.Section>
        </Layout>
      </Page.Body>
    </Page>
  );
};

export default ConfigsPage;
