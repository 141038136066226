import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Link,
  Tag,
  Text,
  Title,
  IconButton,
  useToast,
  Button,
  Icon,
} from '@nimbus-ds/components';
import { Layout, Page } from '@nimbus-ds/patterns';
import { produce } from 'immer';
import apiInstance from '../../utils/apiUtils';
import nexo from '../../nexoClient';
import { ArrowRightIcon, PrinterIcon } from '@nimbus-ds/icons';
import { goTo, navigateHeaderRemove } from '@tiendanube/nexo';

import {
  defaultStoreConfig,
  IStoreConfig,
} from '../../lib/interfaces/store-config.interface';
import { useTranslation } from 'react-i18next';
import { formatTime } from '../../utils/formatUtils';
import { IDistributionCenter } from '../../lib/interfaces/distribution-center.interface';

function PageMain() {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const FILTER_LS_NAME = 'distributionCentersFilter';

  const [loading, setLoading] = useState(true);
  const [distributionCenters, setDistributionCenters] = useState<
    IDistributionCenter[]
  >([]);
  const [filterValue, setFilterValue] = useState<string>('');
  const [columns, setColumns] = useState({
    ordersCol1: [],
    ordersCol2: [],
    ordersCol3: [],
    ordersCol4: [],
    ordersCol5: [],
  });

  useEffect(() => {
    navigateHeaderRemove(nexo);
  }, []);

  const [storeConfig, setStoreConfig] = useState<IStoreConfig>({
    ...defaultStoreConfig,
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await apiInstance.get(`/store-config`);
        if (result.data !== null) {
          const resData = result.data;
          setStoreConfig({ ...defaultStoreConfig, ...resData });
        }
      } catch (error) {
        setStoreConfig({ ...defaultStoreConfig });
        addToast({
          id: 'error_get' + new Date().toISOString(),
          type: 'danger',
          text: `${t('ConfigsPage.Toasts.Error.GetConfig')}`,
          position: 4,
        });
      }
    };

    getData();
    fetchOrders();

    try {
      const filterInCache = localStorage.getItem(FILTER_LS_NAME);
      if (filterInCache) {
        setFilterValue(filterInCache);
      }
    } catch (error) {
      console.log(`Nenhum filtro aplicado`);
    }
  }, []);

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const response = await apiInstance.get('/shipping-info/orders');

      if (response.status !== 200) {
        addToast({
          id: 'error_get' + new Date().toISOString(),
          type: 'danger',
          text: `Erro ao consultar pedidos`,
          position: 4,
        });
        setLoading(false);
        return;
      }
      const { orders, distributionCenters } = response.data;

      setDistributionCenters(distributionCenters);

      // Atualiza as colunas de pedidos
      setColumns((prev) =>
        produce(prev, (draft: any) => {
          const addedOrders = new Set();

          const filterAndAdd = (condition: any) => (order: any) => {
            if (condition(order) && !addedOrders.has(order.id)) {
              addedOrders.add(order.id);
              return true;
            }
            return false;
          };

          draft.ordersCancelled = orders.filter(
            filterAndAdd((order: any) => order.status === 'cancelled'),
          );

          draft.ordersCol5 = orders.filter(
            filterAndAdd((order: any) => order.status === 'closed'),
          );

          draft.ordersCol1 = orders.filter(
            filterAndAdd(
              (order: any) => order.next_action === 'waiting_packing',
            ),
          );
          draft.ordersCol2 = orders.filter(
            filterAndAdd(
              (order: any) => order.next_action === 'waiting_pickup',
            ),
          );
          draft.ordersCol3 = orders.filter(
            filterAndAdd(
              (order: any) => order.next_action === 'waiting_shipment',
            ),
          );
          draft.ordersCol4 = orders.filter(
            filterAndAdd((order: any) => order.next_action === 'close'),
          );
        }),
      );
    } catch (error) {
      console.error('Erro ao buscar pedidos:', error);
    } finally {
      setLoading(false);
    }
  };

  function formatDate(dateString: string) {
    const date = new Date(dateString);

    // Extraindo partes da data
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses começam de 0
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  const handlePrint = (order: any) => {
    const printContent = `
      <div id="print-section">
        <p class="text-g text-center">PRODUÇÃO</p>
        <p class="text-g text-center">#${order.number}</p>
        <p class="text-g text-center">${formatDate(order.created_at)}</p>
        <br />
        <br />
        <p>Entrega: ${order.deliveryDate}</p>
        <p>Período: ${order.period}</p>
        <p>Cliente: ${order.contact_name}</p>
        <p>Para: ${order.recipientName}</p>
        <br />
        <p>Endreço: ${order.shipping_address.address}, ${
          order.shipping_address.number
        } - ${order.shipping_address.locality} - ${order.shipping_address.city}/${
          order.shipping_address.province
        } - ${order.shipping_address.zipcode}</p>
        <p>Referencia: ${order.shipping_address.floor}</p>
        <br />
        <table>
            <thead>
                <tr>
                    <th>QTD</th>
                    <th>Descrição</th>
                    <th>SKU</th>
                </tr>
            </thead>
            <tbody>
              ${order.products
                .map(
                  (item: any) =>
                    `<tr>
                        <td>${item.quantity}</td>
                        <td>${item.name}</td>
                        <td>${item.sku || ''}</td>
                    </tr>`,
                )
                .join('')}
            </tbody>
        </table>
        <br />
        <p><small><b>OBS:</b> ${order.owner_note}</small></p>
        ${
          storeConfig.printerConfig.enableQRCode
            ? `
            <br />
            <br />
            <hr />
            <br />
            <h5>MENSAGEM DO CARTÃO</h5>
            <img src="https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=https://meusite.com.br?pedido=123&cliente=1233" alt="QR Code" width="100px" style="display: block; margin: 5px auto;">
            <hr />
            <br />
            <h5>CONFERÊNCIA & EMBALAGEM</h5>
            <p class="text-center">FOTO - PRONTO PARA ENVIO</p>
            <img src="https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=https://meusite.com.br?pedido=123&cliente=1233" alt="QR Code" width="100px" style="display: block; margin: 5px auto;">
          `
            : ''
        }
      </div>
    `;

    const printWindow = window.open('', '_blank', 'width=800,height=600');
    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Impressão</title>
            <style>
              /* Reset e estilos gerais */
              body {
                width: ${
                  storeConfig.printerConfig.paperWidth || '80mm'
                }; /* ou 80mm, dependendo do modelo */
                margin: 0 auto; /* Centraliza horizontalmente */
                font-size: 10px; /* Ajusta tamanho da fonte */
                line-height: 1.2; /* Melhor legibilidade */
                padding: 0;
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
                color: black;
              }

              /* Tabela */
              table {
                border-collapse: collapse;
                border: 0 none;
                width: 100%;
                margin-top: 10px;
              }

              table th, table td {
                border: none;
                font-size: 12px;
                line-height: 14px;
                padding: 5px;
                text-align: left;
                color: black;
              }

              table th {
                font-weight: bold;
              }

              table td {
                
              }

              #print-section {
                padding: 0 5mm; /* Margens internas para o conteúdo */
              }

              /* Separadores e elementos */
              hr {
                border: none;
                border-top: 1px solid #000;
                margin: 10px 0;
              }

              img {
                display: block;
                margin: 10px auto;
              }

              p, h5, h6 {
                margin: 2px 0;
                word-wrap: break-word; /* Quebra de linha automática */
              }

              img {
                max-width: 50px; /* Reduzir imagens para caber */
              }

              p {
                font-size: 12px;
                line-height: 14px;
                font-weight: bold;
                margin: 3px 0;
              }
                
              .text-g {
                font-size: 15px;
                line-height: 18px;
                font-weight: bold;
                margin: 10px 0;
              }

              small {
                font-size: 12px;
              }

              h5 {
                font-size: 14px;
                line-height: 18px;
                font-weight: bold;
                text-align: center;
                margin: 5px 0;
              }

              h6 {
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                margin: 5px 0;
              }

              .text-center{
                text-align: center;
              }

              /* Estilos específicos para impressão */
              @media print {
                body {
                  margin: 0;
                  padding: 0;
                }

                @page {
                  size: 58mm auto; /* Define o tamanho do papel */
                  margin: 0; /* Remove margens padrão */
                }

                hr {
                  border: none;
                  border-top: 1px solid #000;
                }
              }
            </style>
          </head>
          <body onload="window.print(); window.close();">
            ${printContent}
          </body>
        </html>
      `);
      printWindow.document.close();
    }
  };

  const handleNextAction = async (order: any) => {
    try {
      const result = await apiInstance.post(
        `/shipping-info/orders/${order.id}/next-action`,
      );
      if (result.status === 201) {
        fetchOrders();
      }
    } catch (error) {
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'danger',
        text: `Erro ao mudar status do pedido`,
        position: 4,
      });
    }
  };

  const renderColumn = (id: string, title: string, items: any[]) => (
    <Box
      key={id}
      width="280px"
      borderStyle="solid"
      borderWidth="1"
      borderRadius="2"
      borderColor="neutral-surfaceDisabled"
      padding="2"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="4"
      >
        <Title as="h6">{title}</Title>
        <Tag appearance="primary">
          <Text color="primary-textLow">{items.length}</Text>
        </Tag>
      </Box>
      {loading ? (
        <Box marginY="4">
          <Card padding="small">
            <Card.Header>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" gap="2">
                  <Text.Skeleton width="100px" />
                  <IconButton.Skeleton />
                </Box>
                <Tag.Skeleton />
              </Box>
            </Card.Header>
            <Card.Body>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text.Skeleton width="10%" />
                <IconButton.Skeleton />
              </Box>
              <Text.Skeleton width="40%" />
              <Text.Skeleton width="100%" />
            </Card.Body>
            <Card.Footer>
              <Box
                display="flex"
                justifyContent="space-between"
                gap="2"
                width="100%"
              >
                <Box display="grid" textAlign="left">
                  <Text.Skeleton width="5%" />
                  <Text.Skeleton width="20%" />
                </Box>
                <Box display="grid">
                  <Text.Skeleton width="5%" />
                  <Text.Skeleton width="20%" />
                </Box>
              </Box>
            </Card.Footer>
          </Card>
        </Box>
      ) : (
        items.map((item) =>
          filterValue === '' ||
          !item.distribution_center ||
          item.distribution_center.id === filterValue ? (
            <Box key={item.id} marginY="4">
              <Card padding="small">
                <Card.Header>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box display="flex" alignItems="center" gap="2">
                      <Text fontSize="caption" fontWeight="bold">
                        <Link
                          as="button"
                          onClick={() => goTo(nexo, `/orders/${item.id}`)}
                        >{`#${item.number}`}</Link>
                      </Text>
                      <IconButton
                        size="25px"
                        disabled={loading}
                        source={<PrinterIcon size="small" />}
                        onClick={() => handlePrint(item)}
                      />
                    </Box>
                    <Tag appearance="neutral">
                      {item.distribution_center
                        ? item.distribution_center.base
                        : '--'}
                    </Tag>
                  </Box>
                </Card.Header>
                <Card.Body>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text fontSize="caption">
                      Valor:{' '}
                      <Text as="span" fontSize="caption" fontWeight="bold">
                        {!isNaN(Number(item.total))
                          ? Number(item.total).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })
                          : ''}
                      </Text>
                    </Text>
                    <IconButton
                      size="25px"
                      disabled={loading}
                      source={<ArrowRightIcon size="small" />}
                      onClick={() => handleNextAction(item)}
                    />
                  </Box>
                  <Text fontSize="caption">
                    Tipo:{' '}
                    <Text as="span" fontSize="caption" fontWeight="bold">
                      {item.shipping_option.split(' - ')[0]}
                    </Text>
                  </Text>
                  <Text fontSize="caption">
                    Cliente:{' '}
                    <Text as="span" fontSize="caption" fontWeight="bold">
                      {item.recipientName &&
                      `${item.recipientName}`.trim() !== ''
                        ? item.recipientName
                        : item.contact_name}
                    </Text>
                  </Text>
                </Card.Body>
                <Card.Footer>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    gap="2"
                    width="100%"
                  >
                    <Box display="grid" textAlign="left">
                      <Text as="span" fontSize="caption">
                        Realizado
                      </Text>
                      <Text as="span" fontSize="caption" fontWeight="bold">
                        {formatTime(item.paid_at)}
                      </Text>
                    </Box>
                    <Box display="grid">
                      <Text textAlign="right" as="span" fontSize="caption">
                        Entrega{' '}
                      </Text>
                      <Text
                        textAlign="right"
                        as="span"
                        fontSize="caption"
                        fontWeight="bold"
                      >
                        {item.period_object?.time || '--'}
                      </Text>
                    </Box>
                  </Box>
                </Card.Footer>
              </Card>
            </Box>
          ) : (
            <></>
          ),
        )
      )}
    </Box>
  );

  const toogleFilter = (distributionCenter: IDistributionCenter) => {
    if (!distributionCenter._id) return;
    try {
      if (filterValue === distributionCenter._id) {
        setFilterValue('');
        localStorage.removeItem(FILTER_LS_NAME);
      } else {
        setFilterValue(distributionCenter._id);
        localStorage.setItem(FILTER_LS_NAME, distributionCenter._id);
      }
    } catch (error) {
      console.log(`error toogle filter button`, error);
    }
  };

  return (
    <Page>
      <Page.Header title="Delivery" />
      <Page.Body>
        <Layout columns="1">
          <Layout.Section>
            {distributionCenters.length ? (
              <Box
                display="flex"
                gap="2"
                alignItems="center"
                overflowX="auto"
                overflowY="visible"
              >
                {distributionCenters.map((item) => (
                  <Button
                    key={item._id}
                    appearance={
                      filterValue === item._id ? 'primary' : 'neutral'
                    }
                    onClick={() => toogleFilter(item)}
                  >
                    {item.base}
                  </Button>
                ))}
              </Box>
            ) : (
              <></>
            )}
            <Box
              display="flex"
              gap="2"
              alignItems="stretch"
              overflowX="auto"
              overflowY="visible"
            >
              {renderColumn(
                'ordersCol1',
                'Aguardando Preparação',
                columns.ordersCol1,
              )}
              {renderColumn(
                'ordersCol2',
                'Pedidos Em Preparação',
                columns.ordersCol2,
              )}
              {renderColumn(
                'ordersCol3',
                'Pedidos Prontos',
                columns.ordersCol3,
              )}
              {renderColumn(
                'ordersCol4',
                'Em Rota de Entrega',
                columns.ordersCol4,
              )}
              {renderColumn(
                'ordersCol5',
                'Pedidos Entregues',
                columns.ordersCol5,
              )}
            </Box>
          </Layout.Section>
        </Layout>
      </Page.Body>
    </Page>
  );
}

export default PageMain;
