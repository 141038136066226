import React from 'react';
import {
  Box,
  Button,
  Input,
  Sidebar,
  Spinner,
  Text,
} from '@nimbus-ds/components';
import { ChangeEvent } from 'react';
import { FormField } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';

interface PeriodFormProps {
  open: boolean;
  onClose: () => void;
  formState: {
    name?: string;
  };
  onChange: (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => void;
  onSubmit: () => void;
  loading: boolean;
}

const ConfigCarrierForm: React.FC<PeriodFormProps> = ({
  open,
  onClose,
  formState,
  onChange,
  onSubmit,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <Sidebar maxWidth="500px" padding="base" open={open} onRemove={onClose}>
      <Sidebar.Header title="Período de Entrega" />
      <Box display="grid" gap="4">
        <Text>
          Ao realizar o cadastro, iremos criar um meio de envio customizado para
          sua loja. Não remova ou altere durante o uso do aplicativo.
        </Text>
        <FormField label="Nome da forma de envio">
          <Input
            type="text"
            name="name"
            placeholder="Ex.: Entrega agendada"
            value={formState.name || ''}
            onChange={onChange}
            disabled={loading}
          />
        </FormField>
        <Button onClick={onSubmit} appearance="primary" disabled={loading}>
          {loading ? (
            <>
              <Spinner color="currentColor" size="small" />
              {t(`general.saving`)}
            </>
          ) : (
            t(`general.save`)
          )}
        </Button>
      </Box>
    </Sidebar>
  );
};
export default ConfigCarrierForm;
