export const formatMoney = (value: number | string): string => {
  try {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    let currency = 'BRL';
    let lang = 'pt';

    try {
      const storeInfo = localStorage.getItem('store_info') || '{}';
      const storeInfoJson = JSON.parse(storeInfo);
      if ('currency' in storeInfoJson && 'language' in storeInfoJson) {
        currency = storeInfoJson.currency;
        lang = storeInfoJson.language;
      }
    } catch (error) {
      console.error(error);
    }

    const money = value.toLocaleString(lang, { style: 'currency', currency });
    return money;
  } catch (error) {
    console.error(error);
    return `${value}`;
  }
};

export const formatDate = (
  date: string | Date,
  monthType: 'long' | 'numeric' = 'numeric',
): string => {
  try {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    const options: any = {
      year: 'numeric',
      month: monthType,
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    return date.toLocaleString(navigator.language || 'pt', options);
  } catch (error) {
    return '';
  }
};

export const formatTime = (
  date: string | Date,
  formatTime = 'HH:MM',
): string => {
  try {
    // Converter string para objeto Date
    if (typeof date === 'string') {
      // Ajustar o formato do fuso horário (+0000 para +00:00)
      const formattedDate = date.replace(/(\+|-)(\d{2})(\d{2})$/, '$1$2:$3');
      date = new Date(formattedDate); // Criar o objeto Date
    }

    // Validar se o objeto Date é válido
    if (isNaN(date.getTime())) {
      throw new Error('Data inválida');
    }

    // Extrair horas, minutos e segundos com zero à esquerda
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Substituir no formato especificado
    return formatTime
      .replace(/HH/g, hours)
      .replace(/MM/g, minutes)
      .replace(/SS/g, seconds);
  } catch (error) {
    console.error('Erro ao formatar a data:', error);
    return '';
  }
};
