import { useEffect, useState } from 'react';
import { Box, Text, Button, Input } from '@nimbus-ds/components';
import { FormField, Layout, Page } from '@nimbus-ds/patterns';
import { navigateHeaderRemove } from '@tiendanube/nexo';

import nexo from '../../nexoClient';
import { useTranslation } from 'react-i18next';
import apiInstance from '../../utils/apiUtils';

function OrdersPage() {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]); // Estado para armazenar os pedidos
  const [filters, setFilters] = useState({
    delivery_date: '',
    period: '',
    date_init: '',
    date_end: '',
  });

  useEffect(() => {
    navigateHeaderRemove(nexo);
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await apiInstance.get('/shipping-info/orders', {
        params: {
          delivery_date: filters.delivery_date || undefined,
          period: filters.period || undefined,
          date_init: filters.date_init || undefined,
          date_end: filters.date_end || undefined,
        },
      });
      setOrders(response.data); // Atualiza o estado com os pedidos retornados
    } catch (error) {
      console.error('Erro ao buscar pedidos:', error);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  return (
    <>
      <Page>
        <Page.Header title={t('OrdersPage.title')} />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <Box display="grid" gap="4" maxWidth={{ md: '50%' }}>
                {/* Filtros */}
                <FormField label="Data de entrega">
                  <Input
                    name="delivery_date"
                    type="date"
                    value={filters.delivery_date}
                    onChange={handleInputChange}
                  />
                </FormField>
                <FormField label="Período">
                  <Input
                    name="period"
                    value={filters.period}
                    onChange={handleInputChange}
                  />
                </FormField>
                <FormField label="Data inicial">
                  <Input
                    name="date_init"
                    type="date"
                    value={filters.date_init}
                    onChange={handleInputChange}
                  />
                </FormField>
                <FormField label="Data inicial">
                  <Input
                    name="date_end"
                    type="date"
                    value={filters.date_end}
                    onChange={handleInputChange}
                  />
                </FormField>
                <Button appearance="primary" onClick={fetchOrders}>
                  Buscar Pedidos
                </Button>

                {/* Lista de pedidos */}
                {orders.length > 0 ? (
                  orders.map((order: any) => (
                    <Box key={order.id} padding="2">
                      <Text>
                        <strong>ID:</strong> {order.id}
                      </Text>
                      <Text>
                        <strong>Data de Entrega:</strong> {order.delivery_date}
                      </Text>
                      <Text>
                        <strong>Período:</strong> {order.period}
                      </Text>
                    </Box>
                  ))
                ) : (
                  <Text>Nenhum pedido encontrado</Text>
                )}
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
}

export default OrdersPage;
