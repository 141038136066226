import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Icon,
  Input,
  Link,
  Spinner,
  Table,
  Tag,
  Text,
  Title,
  Tooltip,
} from '@nimbus-ds/components';
import apiInstance from '../../utils/apiUtils';
import {
  IShippingConfigPeriod,
  IShippingConfigZipBand,
} from '../../lib/interfaces/shipping-config.interface';
import { useToast } from '@nimbus-ds/components';
import { EmptyMessage, Layout, Page } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { navigateHeader } from '@tiendanube/nexo';
import nexo from '../../nexoClient';
import {
  CheckCircleIcon,
  EditIcon,
  ForbiddenIcon,
  MoneyIcon,
  PlusCircleIcon,
} from '@nimbus-ds/icons';

function AssociatePeriodsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { zipBandId } = useParams<{ zipBandId: string }>();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [zipBand, setZipBand] = useState<IShippingConfigZipBand>();
  const [periods, setPeriods] = useState<IShippingConfigPeriod[]>([]);
  const [formData, setFormData] = useState<
    Record<
      string,
      { week_price: number; saturdays_price: number; sundays_price: number }
    >
  >({});

  useEffect(() => {
    navigateHeader(nexo, {
      goTo: '/shipping-config',
      text: `${t('ShippingConfigPage.title')}`,
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const zipBandResponse = await apiInstance.get(
          `/shipping-config/zip-bands/${zipBandId}`,
        );
        console.log('Zip Band Response:', zipBandResponse.data);

        const periodsResponse = await apiInstance.get(
          `/shipping-config/periods?active=true`,
        );
        console.log('Active Periods Response:', periodsResponse.data);

        const activePeriods = periodsResponse.data || [];

        const allPeriods = activePeriods.map(
          (period: IShippingConfigPeriod) => {
            const associatedPeriod = zipBandResponse.data.periods.find(
              (p: IShippingConfigPeriod) => p._id === period._id,
            );

            return {
              ...period,
              period_prices: associatedPeriod?.period_prices || [],
            };
          },
        );

        console.log('Merged Periods:', allPeriods);

        setZipBand(zipBandResponse.data);
        setPeriods(allPeriods);

        const initialFormData = allPeriods.reduce(
          (
            acc: Record<
              string,
              {
                week_price: number;
                saturdays_price: number;
                sundays_price: number;
              }
            >,
            period: IShippingConfigPeriod,
          ) => {
            if (!period._id) return acc;
            return {
              ...acc,
              [period._id]: {
                week_price: period.period_prices?.[0]?.week_price || 0,
                saturdays_price:
                  period.period_prices?.[0]?.saturdays_price || 0,
                sundays_price: period.period_prices?.[0]?.sundays_price || 0,
              },
            };
          },
          {},
        );
        setFormData(initialFormData);
      } catch (error) {
        console.error(error);
        addToast({
          id: `error_${new Date().toISOString()}`,
          type: 'danger',
          text: 'Erro ao buscar dados.',
          position: 4,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [zipBandId, addToast]);

  const handleChange = (periodId: string, field: string, value: string) => {
    if (!/^\d*\.?\d*$/.test(value)) return; // Validar apenas números e ponto decimal
    setFormData((prev) => ({
      ...prev,
      [periodId]: {
        ...prev[periodId],
        [field]: parseFloat(value) || 0, // Converter o valor para número
      },
    }));
  };

  const handleSubmit = async () => {
    setSaving(true);
    try {
      const payload = Object.entries(formData)
        .filter(
          ([, data]) =>
            data.week_price !== undefined ||
            data.saturdays_price !== undefined ||
            data.sundays_price !== undefined,
        )
        .map(([period_id, prices]) => ({
          period_id,
          week_price: Number(prices.week_price) || 0,
          saturdays_price: Number(prices.saturdays_price) || 0,
          sundays_price: Number(prices.sundays_price) || 0,
        }));

      await apiInstance.put(
        `/shipping-config/zip-bands/${zipBandId}/save-period-prices`,
        {
          periodPrices: payload,
        },
      );

      addToast({
        id: `success_${new Date().toISOString()}`,
        type: 'success',
        text: 'Configurações salvas com sucesso.',
        position: 4,
      });
    } catch (error) {
      addToast({
        id: `error_${new Date().toISOString()}`,
        type: 'danger',
        text: 'Erro ao salvar configurações.',
        position: 4,
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <Page>
        <Page.Header title={t('AssociatePeriodsPage.Title')} />
        <Page.Body display="grid" gap="8">
          <Layout columns="1">
            <Layout.Section>
              {loading ? (
                <Box display="grid" gap="4">
                  <Card>
                    <Card.Header>
                      <Title.Skeleton />
                    </Card.Header>
                    <Card.Body>
                      <Text.Skeleton />
                      <Box marginY="4" display="flex" flexWrap="nowrap" gap="6">
                        <Text.Skeleton width="100px" />
                        <Text.Skeleton width="100px" />
                        <Text.Skeleton width="100px" />
                        <Text.Skeleton width="100px" />
                      </Box>
                      <Link.Skeleton />
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Title.Skeleton />
                    </Card.Header>
                    <Card.Body>
                      <Table>
                        <Table.Head>
                          <Table.Row>
                            <Table.Cell as="th" width="50px">
                              <Text.Skeleton />
                            </Table.Cell>
                            <Table.Cell as="th" width="180px">
                              <Text.Skeleton />
                            </Table.Cell>
                            <Table.Cell as="th">
                              <Text.Skeleton />
                            </Table.Cell>
                            <Table.Cell as="th">
                              <Text.Skeleton />
                            </Table.Cell>
                            <Table.Cell as="th" width="120px">
                              <Text.Skeleton />
                            </Table.Cell>
                            <Table.Cell as="th" width="120px">
                              <Text.Skeleton />
                            </Table.Cell>
                            <Table.Cell as="th" width="120px">
                              <Text.Skeleton />
                            </Table.Cell>
                          </Table.Row>
                        </Table.Head>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>
                              <Box
                                as="span"
                                display="flex"
                                justifyContent="center"
                              >
                                <Text.Skeleton />
                              </Box>
                            </Table.Cell>
                            <Table.Cell>
                              <Text.Skeleton />
                            </Table.Cell>
                            <Table.Cell>
                              <Text.Skeleton />
                            </Table.Cell>
                            <Table.Cell>
                              <Text.Skeleton />
                            </Table.Cell>
                            <Table.Cell>
                              <Input.Skeleton />
                            </Table.Cell>
                            <Table.Cell>
                              <Input.Skeleton />
                            </Table.Cell>
                            <Table.Cell>
                              <Input.Skeleton />
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Card.Body>
                    <Card.Footer>
                      <Button.Skeleton />
                    </Card.Footer>
                  </Card>
                </Box>
              ) : (
                <Box display="grid" gap="4">
                  <Card>
                    <Card.Header>
                      <Box display="flex" alignItems="center" gap="4">
                        <Title as="h4">{`${t(
                          'AssociatePeriodsPage.CardZipBan.Title',
                        )}`}</Title>
                        <Tag
                          appearance={zipBand?.active ? 'success' : 'danger'}
                        >
                          {zipBand?.active ? (
                            <>
                              <CheckCircleIcon size={12} />
                              <Text color="success-textLow">{`${t(
                                'general.active',
                              )}`}</Text>
                            </>
                          ) : (
                            <>
                              <ForbiddenIcon size={12} />
                              <Text color="danger-textLow">{`${t(
                                'general.inactive',
                              )}`}</Text>
                            </>
                          )}
                        </Tag>
                      </Box>
                    </Card.Header>
                    <Card.Body>
                      <Text>{`${t(
                        'AssociatePeriodsPage.CardZipBan.Description',
                      )}`}</Text>
                      <Box marginY="4" display="flex" flexWrap="nowrap" gap="6">
                        <Box display="flex" gap="2">
                          <Text fontWeight="bold">
                            {`${t('AssociatePeriodsPage.CardZipBan.Label1')}`}:
                          </Text>
                          <Text>
                            {zipBand?.city}/{zipBand?.state}
                          </Text>
                        </Box>
                        <Box display="flex" gap="2">
                          <Text fontWeight="bold">
                            {`${t('AssociatePeriodsPage.CardZipBan.Label2')}`}:
                          </Text>
                          <Text>{zipBand?.zip_start}</Text>
                        </Box>
                        <Box display="flex" gap="2">
                          <Text fontWeight="bold">
                            {`${t('AssociatePeriodsPage.CardZipBan.Label3')}`}:
                          </Text>
                          <Text>{zipBand?.zip_end}</Text>
                        </Box>
                      </Box>
                      <Link
                        appearance="primary"
                        as="button"
                        onClick={() => console.log(`teste`)}
                      >
                        <Icon color="currentColor" source={<EditIcon />} />
                        {`${t('general.edit')}`}
                      </Link>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Header
                      title={`${t('AssociatePeriodsPage.CardPrices.Title')}`}
                    />
                    <Card.Body>
                      {periods.length ? (
                        <Table>
                          <Table.Head>
                            <Table.Row>
                              <Table.Cell as="th" width="50px">
                                {``}
                              </Table.Cell>
                              <Table.Cell as="th" width="180px">
                                {`${t(
                                  'AssociatePeriodsPage.CardPrices.Table.Head.Title1',
                                )}`}
                              </Table.Cell>
                              <Table.Cell as="th">
                                {`${t(
                                  'AssociatePeriodsPage.CardPrices.Table.Head.Title2',
                                )}`}
                              </Table.Cell>
                              <Table.Cell as="th">
                                {`${t(
                                  'AssociatePeriodsPage.CardPrices.Table.Head.Title3',
                                )}`}
                              </Table.Cell>
                              <Table.Cell as="th" width="120px">
                                {`${t(
                                  'AssociatePeriodsPage.CardPrices.Table.Head.Title4',
                                )}`}
                              </Table.Cell>
                              <Table.Cell as="th" width="120px">
                                {`${t(
                                  'AssociatePeriodsPage.CardPrices.Table.Head.Title5',
                                )}`}
                              </Table.Cell>
                              <Table.Cell as="th" width="120px">
                                {`${t(
                                  'AssociatePeriodsPage.CardPrices.Table.Head.Title6',
                                )}`}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Head>
                          <Table.Body>
                            {periods.map((period) =>
                              period._id && typeof period._id === 'string' ? (
                                <Table.Row key={period._id}>
                                  <Table.Cell>
                                    <Box
                                      as="span"
                                      display="flex"
                                      justifyContent="center"
                                    >
                                      {period.active ? (
                                        <Tooltip
                                          content="Este período está ativo e visível na loja"
                                          position="right"
                                        >
                                          <Icon
                                            color="success-textLow"
                                            source={
                                              <CheckCircleIcon size="medium" />
                                            }
                                          />
                                        </Tooltip>
                                      ) : (
                                        <Tooltip
                                          content="Este período está inativo e não fica visível na loja"
                                          position="right"
                                        >
                                          <Icon
                                            color="danger-textLow"
                                            source={
                                              <ForbiddenIcon size="medium" />
                                            }
                                          />
                                        </Tooltip>
                                      )}
                                    </Box>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Text fontSize="highlight">
                                      {period.name}
                                    </Text>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Text fontSize="highlight">
                                      {period.time}
                                    </Text>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Text fontSize="caption">
                                      Semana: {period.cut_off_week.time}
                                      {` `}
                                      {period.cut_off_week.previous_day
                                        ? `(Dia anterior)`
                                        : ''}
                                    </Text>
                                    <Text fontSize="caption">
                                      Sabádo: {period.cut_off_saturday.time}
                                      {` `}
                                      {period.cut_off_saturday.previous_day
                                        ? `(Dia anterior)`
                                        : ''}
                                    </Text>
                                    <Text fontSize="caption">
                                      Sabádo (Interior):{' '}
                                      {period.cut_off_saturday_interior.time}
                                      {` `}
                                      {period.cut_off_saturday_interior
                                        .previous_day
                                        ? `(Dia anterior)`
                                        : ''}
                                    </Text>
                                    <Text fontSize="caption">
                                      Domingo: {period.cut_off_sunday.time}
                                      {` `}
                                      {period.cut_off_sunday.previous_day
                                        ? `(Dia anterior)`
                                        : ''}
                                    </Text>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Input
                                      append={
                                        <Icon
                                          source={
                                            <Icon source={<MoneyIcon />} />
                                          }
                                        />
                                      }
                                      name={`week_price_${period._id}`}
                                      min={0}
                                      step={0.01}
                                      type="number"
                                      disabled={saving}
                                      value={
                                        formData[period._id]?.week_price || ''
                                      }
                                      onChange={(e) =>
                                        handleChange(
                                          period._id ? period._id : '',
                                          'week_price',
                                          e.target.value,
                                        )
                                      }
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Input
                                      append={
                                        <Icon
                                          source={
                                            <Icon source={<MoneyIcon />} />
                                          }
                                        />
                                      }
                                      name={`saturdays_price_${period._id}`}
                                      min={0}
                                      step={0.01}
                                      type="number"
                                      disabled={saving}
                                      value={
                                        formData[period._id]?.saturdays_price ||
                                        ''
                                      }
                                      onChange={(e) =>
                                        handleChange(
                                          period._id ? period._id : '',
                                          'saturdays_price',
                                          e.target.value,
                                        )
                                      }
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Input
                                      append={
                                        <Icon
                                          source={
                                            <Icon source={<MoneyIcon />} />
                                          }
                                        />
                                      }
                                      name={`sundays_price_${period._id}`}
                                      min={0}
                                      step={0.01}
                                      type="number"
                                      disabled={saving}
                                      value={
                                        formData[period._id]?.sundays_price ||
                                        ''
                                      }
                                      onChange={(e) =>
                                        handleChange(
                                          period._id ? period._id : '',
                                          'sundays_price',
                                          e.target.value,
                                        )
                                      }
                                    />
                                  </Table.Cell>
                                </Table.Row>
                              ) : null,
                            )}
                          </Table.Body>
                        </Table>
                      ) : (
                        <EmptyMessage
                          title={`${t(
                            'AssociatePeriodsPage.CardPrices.EmptyMessage.Title',
                          )}`}
                          text={`${t(
                            'AssociatePeriodsPage.CardPrices.EmptyMessage.Text',
                          )}`}
                          icon={<ForbiddenIcon size={32} />}
                          actions={
                            <Link
                              appearance="primary"
                              as="button"
                              onClick={() => navigate('/shipping-config')}
                            >
                              <Icon
                                color="currentColor"
                                source={<PlusCircleIcon />}
                              />
                              {`${t(
                                'AssociatePeriodsPage.CardPrices.EmptyMessage.CtaGoBack',
                              )}`}
                            </Link>
                          }
                        />
                      )}
                    </Card.Body>
                    {periods.length ? (
                      <Card.Footer>
                        <Button
                          onClick={handleSubmit}
                          appearance="primary"
                          disabled={saving}
                        >
                          {saving ? (
                            <>
                              <Spinner color="currentColor" size="small" />
                              {`${t(`general.save`)}`}
                            </>
                          ) : (
                            t(`general.save`)
                          )}
                        </Button>
                      </Card.Footer>
                    ) : (
                      <></>
                    )}
                  </Card>
                </Box>
              )}
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
}

export default AssociatePeriodsPage;
