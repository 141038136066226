export type QZTraySize = '80mm' | '58mm' | '100mm'; // Exemplos de tamanhos suportados

export const QZTrayMapSize = (size: string): QZTraySize | undefined => {
  switch (size) {
    case '80mm':
      return '80mm' as QZTraySize; // Valor compatível
    case '58mm':
      return '58mm' as QZTraySize;
    default:
      return undefined; // Valor padrão se não for compatível
  }
};

export interface IStoreConfig {
  _id?: string;
  store_id: number;
  deliveryScheduling: {
    active: boolean;
    calendaryTitle: string;
  };
  printerConfig: {
    printerName: string; // Nome da impressora selecionada
    paperWidth: QZTraySize; // Largura do papel (ex.: 80mm ou 58mm)
    fontSize: string; // Tamanho da fonte para impressão
    enableQRCode: boolean; // Habilitar/Desabilitar QRCode de entrega
  };
  createdAt?: string;
  updatedAt?: string;
}

export const defaultStoreConfig: IStoreConfig = {
  store_id: -1,
  deliveryScheduling: {
    active: false,
    calendaryTitle: 'Selecione a data e o período de entrega',
  },
  printerConfig: {
    printerName: '',
    paperWidth: '80mm',
    fontSize: '12',
    enableQRCode: true,
  },
};
