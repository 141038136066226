import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Card,
  useToast,
  Icon,
  Text,
  IconButton,
  Button,
  Input,
  Sidebar,
  Title,
  Link,
} from '@nimbus-ds/components';
import {
  DataList,
  EmptyMessage,
  FormField,
  Layout,
  Page,
} from '@nimbus-ds/patterns';
import { TrashIcon, PlusCircleIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import apiInstance from '../../utils/apiUtils';
import nexo from '../../nexoClient';

import {
  EmptyDistributionCenter,
  EmptyDistributionCenterZipBand,
  IDistributionCenter,
  IDistributionCenterZipBand,
} from '../../lib/interfaces/distribution-center.interface';
import { navigateHeader } from '@tiendanube/nexo';

const DistributionCenterFormPage: React.FC = () => {
  const { t } = useTranslation();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [distributionCenter, setDistributionCenter] =
    useState<IDistributionCenter>({ ...EmptyDistributionCenter });
  const [zipBands, setZipBands] = useState<IDistributionCenterZipBand[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [currentZipBand, setCurrentZipBand] =
    useState<IDistributionCenterZipBand>({ ...EmptyDistributionCenterZipBand });

  useEffect(() => {
    navigateHeader(nexo, {
      goTo: '/distribution-centers',
      text: `${t('DistributionCentersPage.Header.Title')}`,
    });
  }, []);

  // Fetch Distribution Center Data
  const fetchDistributionCenter = async () => {
    setLoading(true);
    try {
      if (id) {
        const result = await apiInstance.get(`/distribution-centers/${id}`);
        if (result.data) {
          setDistributionCenter(result.data);
          setZipBands(result.data.zip_bands || []);
        }
      }
    } catch (error) {
      addToast({
        id: `error_fetch_${new Date().toISOString()}`,
        type: 'danger',
        text: `${t('DistributionCenterFormPage.Toast.Fetch.Error')}`,
        position: 4,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddZipBand = () => {
    setZipBands([...zipBands, currentZipBand]);
    setCurrentZipBand({ label: '', zipcode_start: '', zipcode_end: '' });
    setOpenModal(false);
  };

  const handleChangeForm = (
    event: ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;

    setDistributionCenter((prevState) => {
      // Verifica se o campo é aninhado
      const keys = name.split('.');
      const updatedState: any = { ...prevState }; // Alterado para const

      if (keys.length > 1) {
        let nestedState = updatedState;
        for (let i = 0; i < keys.length - 1; i++) {
          if (!nestedState[keys[i]]) nestedState[keys[i]] = {};
          nestedState = nestedState[keys[i]];
        }
        nestedState[keys[keys.length - 1]] =
          type === 'checkbox' && 'checked' in event.target
            ? event.target.checked
            : value;
      } else {
        updatedState[name] =
          type === 'checkbox' && 'checked' in event.target
            ? event.target.checked
            : value;
      }

      return updatedState;
    });
  };

  const handleRemoveZipBand = (index: number) => {
    setZipBands((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    setLoadingForm(true);
    try {
      const payload = {
        ...distributionCenter,
        zip_bands: zipBands,
      };

      let result;
      if (id) {
        result = await apiInstance.put(`/distribution-centers/${id}`, payload);
      } else {
        result = await apiInstance.post(`/distribution-centers`, payload);
      }

      if (result && (result.status === 200 || result.status === 201)) {
        addToast({
          id: `success_save_${new Date().toISOString()}`,
          type: 'success',
          text: `${t('DistributionCenterFormPage.Toast.Save.Success')}`,
          position: 4,
        });

        navigate('/distribution-centers');
      }
    } catch (error: any) {
      addToast({
        id: `error_save_${new Date().toISOString()}`,
        type: 'danger',
        text: `${error.response ? error.response.data.message : t('DistributionCenterFormPage.Toast.Save.Error')}`,
        position: 4,
      });
    } finally {
      setLoadingForm(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchDistributionCenter();
    } else {
      setDistributionCenter({ ...EmptyDistributionCenter });
    }
  }, [id]);

  return (
    <Page>
      <Page.Header
        title={
          id
            ? t('DistributionCenterFormPage.Header.EditTitle')
            : t('DistributionCenterFormPage.Header.NewTitle')
        }
      />
      <Page.Body display="grid" gap="8">
        <Layout columns="2 - symmetric">
          <Layout.Section>
            <Card>
              <Card.Header
                title={`${t('DistributionCenterFormPage.Card.Title')}`}
              />
              <Card.Body>
                {loading ? (
                  <Input.Skeleton width="20%" />
                ) : (
                  <Box display="grid" gap="4">
                    <FormField
                      label={`${t('DistributionCenterFormPage.Input.Base')}`}
                    >
                      <Input
                        id="base"
                        name="base"
                        disabled={loading}
                        value={distributionCenter?.base || ''}
                        onChange={handleChangeForm}
                      />
                    </FormField>
                    <Button
                      appearance="primary"
                      onClick={handleSave}
                      disabled={loadingForm}
                    >
                      {t('DistributionCenterFormPage.SaveButton')}
                    </Button>
                  </Box>
                )}
              </Card.Body>
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card padding="none">
              <Card.Header padding="base">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Title as="h4">
                    {t('DistributionCenterFormPage.ZipBands.Title')}
                  </Title>
                  <Link
                    as="button"
                    appearance="primary"
                    onClick={() => setOpenModal(true)}
                  >
                    <Icon color="currentColor" source={<PlusCircleIcon />} />
                    {t('DistributionCenterFormPage.ZipBands.New')}
                  </Link>
                </Box>
              </Card.Header>
              <Card.Body>
                <DataList>
                  {zipBands.length ? (
                    zipBands.map((band, index) => (
                      <DataList.Row key={index}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box display="grid" gap="2">
                            <Text fontWeight="bold" fontSize="highlight">
                              {band.label}
                            </Text>
                            <Text fontSize="highlight">
                              {band.zipcode_start} - {band.zipcode_end}
                            </Text>
                          </Box>
                          <IconButton
                            size="32px"
                            type="button"
                            title="Remover"
                            onClick={() => handleRemoveZipBand(index)}
                            source={<TrashIcon size="small" />}
                          />
                        </Box>
                      </DataList.Row>
                    ))
                  ) : (
                    <DataList.Row>
                      <EmptyMessage
                        title={t(
                          'DistributionCenterFormPage.ZipBands.Empty.Title',
                        )}
                        text={`${t(
                          'DistributionCenterFormPage.ZipBands.Empty.Text',
                        )}`}
                      />
                    </DataList.Row>
                  )}
                </DataList>
              </Card.Body>
            </Card>
          </Layout.Section>
        </Layout>
      </Page.Body>

      {/* Modal for Adding Zip Band */}
      <Sidebar
        maxWidth="500px"
        padding="base"
        open={openModal}
        onRemove={() => setOpenModal(false)}
      >
        <Sidebar.Header
          title={`${t('DistributionCenterFormPage.ZipBands.Modal.Title')}`}
        />
        <Sidebar.Body>
          <Box display="grid" gap="4">
            <FormField
              label={`${t('DistributionCenterFormPage.ZipBands.Input.Label')}`}
            >
              <Input
                value={currentZipBand.label}
                onChange={(e) =>
                  setCurrentZipBand((prev) => ({
                    ...prev,
                    label: e.target.value,
                  }))
                }
              />
            </FormField>
            <FormField
              label={`${t('DistributionCenterFormPage.ZipBands.Input.ZipStart')}`}
            >
              <Input
                value={currentZipBand.zipcode_start}
                onChange={(e) =>
                  setCurrentZipBand((prev) => ({
                    ...prev,
                    zipcode_start: e.target.value,
                  }))
                }
              />
            </FormField>
            <FormField
              label={`${t('DistributionCenterFormPage.ZipBands.Input.ZipEnd')}`}
            >
              <Input
                value={currentZipBand.zipcode_end}
                onChange={(e) =>
                  setCurrentZipBand((prev) => ({
                    ...prev,
                    zipcode_end: e.target.value,
                  }))
                }
              />
            </FormField>
            <Button appearance="primary" onClick={handleAddZipBand}>
              {t('DistributionCenterFormPage.ZipBands.Modal.AddButton')}
            </Button>
          </Box>
        </Sidebar.Body>
      </Sidebar>
    </Page>
  );
};

export default DistributionCenterFormPage;
